<template>
  <div id="profile-view">
    <b-overlay>
      <section class="bg-light ftco-section">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid loader-width"
            alt
          />
        </div>
        <div class="container" v-if="!loader">
          <div class="row flex-lg-row flex-md-row">
            <div class="col-md-5">
              <div style="margin-top: 2rem">
                <div class="card-body bg-dark-gray">
                  <h2 class="text-black">Profile</h2>
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="username"
                        placeholder="User Name*"
                      />
                      <span class="text-danger" v-if="errors.username">{{
                        this.errors.username[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="userData.name"
                        placeholder="Name*"
                      />
                      <span class="text-danger" v-if="errors.name">{{
                        this.errors.name[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        v-model="userData.email"
                        placeholder="Email Address*"
                        :readonly="userData.email != null"
                      />
                      <span class="text-danger" v-if="errors.email">{{
                        this.errors.email[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-2 pr-0">
                          <vue-country-code
                            @onSelect="onSelect"
                            :defaultCountry="country"
                            disabledFetchingCountry
                            enabledCountryCode
                            enabledFlags
                          >
                          </vue-country-code>
                        </div>
                        <div class="col-md-10 pl-0">
                          <input
                            type="text"
                            class="form-control"
                            v-model="mobile"
                            placeholder="Mobile*"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <b-form-group class="font-weight-600">
                        <label class="font-weight-600 mb-3">Gender</label>
                        <div class="row">
                          <div class="col-md-3">
                            <b-form-radio
                              size="lg"
                              class="radio-control"
                              v-model="gender"
                              name="gender"
                              value="Male"
                              >Male</b-form-radio
                            >
                          </div>
                          <div class="col-md-3">
                            <b-form-radio
                              size="lg"
                              class="radio-control"
                              v-model="gender"
                              name="gender"
                              value="Female"
                              >Female</b-form-radio
                            >
                          </div>
                          <span class="text-danger" v-if="errors.gender">{{
                            this.errors.gender[0]
                          }}</span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="row mb-1">
                      <div class="mt-4 mb-2 ml-3">
                        <button
                          @click="UpdateProfile"
                          type="button"
                          class="btn btn-primary btn-block px-4"
                        >
                          Update
                        </button>
                      </div>
                      <div class="mt-4 mb-2 ml-3">
                        <button
                          @click="deleteAccount()"
                          type="button"
                          class="btn btn-primary btn-block px-4"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <img
                src="@/assets/site/images/login-bg.jpg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);
export default {
  name: "PofileView",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Easily manage your profile with our user-friendly interface. Update your information, including username, name, email, mobile, and gender. Enjoy seamless account management today!",
      },
      {
        name: "keywords",
        content:
          "profile management, update profile, user information, account settings, profile template, user-friendly interface, mobile input, gender selection, email management, account deletion, form validation, responsive design, loader animation, profile update button, country code input, user account features.",
      },
    ],
  },
  data() {
    return {
      userData: [],
      errors: [],
      loader: false,
      gender: "",
      enabledCountryCode: true,
      enabledFlags: false,
      countrycode: "",
      country: "",
      edit: "true",
    };
  },
  created() {
    this.setUserDatas();
  },
  methods: {
    onSelect({ iso2, dialCode }) {
      this.countrycode = dialCode;
      this.country = iso2;
    },
    setUserDatas() {
      const loc_UserData = localStorage.getItem("userData");
      const { user } = JSON.parse(loc_UserData);

      this.userData = user;
      this.country = user.country;
      this.countrycode = user.country_code;
      this.gender = user.gender;
      this.mobile = user.mobile;
      this.username = user.name;
    },
    UpdateProfile() {
      //Save or update User Details
      this.errors = [];

      let apiUrl =
        process.env.VUE_APP_URL + "customer/UpdateProfile/" + this.userData.id;
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          name: this.userData.name,
          username: this.username,
          gender: this.gender,
          email: this.userData.email,
          mobile: this.mobile,
          country_code: this.countrycode,
          country: this.country,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
            // this.resetForm();

            var userData = {
              user: data.customer,
              // token: data.token,
              status: true,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
          }
          if (data.status === false) {
            this.$swal("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("Profile ", "Error : " + err.message, "error");
        });
    },
    deleteAccount() {
      // this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      this.$swal
        .fire({
          title: "Are you sure?",
          text:
            "Do you want to remove Account " + userdata_array.user.name + "!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            fetch(process.env.VUE_APP_URL + "customer/deleteAccount", {
              method: "post",
              headers: {
                "content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: "Bearer " + api_token,
              },

              body: JSON.stringify({
                user_id: user_id,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.status === true) {
                  this.loader = false;
                  localStorage.clear();
                  window.location.href = "/";
                  this.$swal.fire("Deleted", data.message, "success");
                } else {
                  this.loader = false;
                  this.$swal.fire("Error!", data.message, "error");
                }
              })
              .catch(function(err) {
                console.log("Fetch Error :-S", err);
              });
          }
        });
    },
    resetForm() {
      this.name = null;
      this.username = null;
      this.gender = null;
      this.email = null;
      this.mobile = null;
      this.errors = [];
    },
  },
};
</script>
